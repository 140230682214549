import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Organization } from '../../../models';

import { Menu, NavMenu, NavMenuBody, NavMenuItem, Button, ContextMenu, DropdownItem } from '@weezevent/nacre';
import OrganizationModal from '../../../pages/organizations/OrganizationModal';
import { SwitchLoadingOptions } from '../../loadingCard';

import css from './sidebar.module.css';

const SideBarMenu = ({ organizationId, capacities }) => {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);

    const Component = React.useMemo(() => {
        if (!organizationId) {
            return RootMenu;
        }
        return OrgaMenu;
    }, [organizationId]);

    return (
        <div className={css['menu-orga']}>
            {window.weezConfig.enableCreateOrganization && (
                <>
                    <OrganizationModal open={open} onClose={() => setOpen(false)} />
                    <div className={css['create-orga-btn']}>
                        <Button fluid primary onClick={() => setOpen(true)}>
                            {t(`sauron.organizations.error.create`)}
                        </Button>
                    </div>
                </>
            )}
            <Component capacities={capacities} organizationId={organizationId} />
        </div>
    );
};

export const RootMenu = ({ capacities }) => {
    const { t } = useTranslation();

    return (
        <Menu useNew capacities={Array.from(capacities)} alternative>
            <NavMenu>
                <NavMenuItem to={'/organizations'} icon="home" exact>
                    {t('common.organizations')}
                </NavMenuItem>
            </NavMenu>

            <NavMenu allowedCapacities={['RoleCapacity.ADMIN']}>
                <NavMenuItem icon="users">{t('common.users')}</NavMenuItem>
                <NavMenuBody>
                    <NavMenuItem to={'/users'} exact>
                        {t('common.users')}
                    </NavMenuItem>
                    <NavMenuItem to={'/roles'} exact>
                        {t('common.roles')}
                    </NavMenuItem>
                </NavMenuBody>
            </NavMenu>

            <NavMenu allowedCapacities={['RoleCapacity.ADMIN']}>
                <NavMenuItem icon="plug">{t('common.integrations')}</NavMenuItem>
                <NavMenuBody>
                    <NavMenuItem to={'/tools/webhooks'} exact>
                        {t('common.webhooks')}
                    </NavMenuItem>
                    <NavMenuItem to={'/tools/formatters'} exact>
                        {t('common.formatters')}
                    </NavMenuItem>
                </NavMenuBody>
            </NavMenu>
        </Menu>
    );
};

const OrgaMenu = ({ capacities, organizationId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [search, setSearch] = React.useState('');

    const [currentOrganization] = Organization.useApiModel(
        {
            id: organizationId,
            cache: false,
            allow_cache: false,
            launch: Boolean(organizationId)
        },
        [organizationId]
    );

    const [organizations, loadingOrganizations] = Organization.useApiModel(
        {
            cache: false,
            allow_cache: false,
            // Limit to 50 organizers to enhance performances
            // If a user has access to more than 50 organizations, the component provides a searchbar
            query: { search, limit: 50 }
        },
        [search]
    );

    const selectItems = React.useMemo(() => {
        if (loadingOrganizations) {
            return SwitchLoadingOptions;
        }
        return organizations?.map(org => (
            <div key={org.pk}>
                <DropdownItem onClick={() => history.push(`/organizations/O${org.id}/users`)}>{org.name || t('sauron.organizations.organization_without_name')}</DropdownItem>
            </div>
        ));
    }, [organizations, loadingOrganizations]);

    return (
        <>
            <ContextMenu
                title={currentOrganization?.name || t('sauron.organizations.organization_without_name')}
                switchMenuProps={{
                    text: t('sauron.organizations.search'),
                    items: selectItems,
                    searchProps: {
                        placeholder: t('common.search'),
                        onChange: (_, value) => setSearch(value),
                        handleReset: () => setSearch('')
                    }
                }}
            />
            <Menu useNew alternative capacities={Array.from(capacities)}>
                <NavMenu>
                    <NavMenuItem icon="users" to={`/organizations/O${organizationId}/users`}>
                        {t('common.users')}
                    </NavMenuItem>
                    <NavMenuItem icon="plug">{t('common.integrations')}</NavMenuItem>
                    <NavMenuBody>
                        <NavMenuItem to={`/organizations/O${organizationId}/tools/webhooks`}>{t('common.webhooks')}</NavMenuItem>
                        <NavMenuItem to={`/organizations/O${organizationId}/tools/formatters`}>{t('common.formatters')}</NavMenuItem>
                    </NavMenuBody>
                </NavMenu>
            </Menu>
        </>
    );
};

export default SideBarMenu;
