import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthRoute from '../../utils/auth/authRoute';
import OrganizationList from '../../pages/organizations/list';
import { UsersRouter } from './UsersRouter';
import { WebhooksRouter } from './WebhooksRouter';

export const OrganizationRouter = ({ match }) => {
    const { path } = match;
    return (
        <Switch>
            <Route exact path={path} render={() => <OrganizationList />} />
            <AuthRoute
                allow={['RoleCapacity.OWNER']}
                path={`${path}/O:organizationId/users`}
                render={basenameProps => {
                    const { organizationId } = basenameProps.match.params;
                    return <UsersRouter match={basenameProps.match} organizationId={organizationId} />;
                }}
            />

            <AuthRoute
                allow={['RoleCapacity.OWNER']}
                path={`${path}/O:organizationId/tools`}
                render={basenameProps => {
                    const { organizationId } = basenameProps.match.params;
                    return <WebhooksRouter match={basenameProps.match} organizationId={organizationId} />;
                }}
            />
        </Switch>
    );
};
