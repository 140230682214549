import React from 'react';
import JSONEditor from 'jsoneditor';
import 'jsoneditor/dist/jsoneditor.css';

import css from './jsonEditor.module.css';

export default function JsonEditorField({ values, loading, onChange, setIsValid }) {
    const containerRef = React.useRef(null);
    const jsoneditorRef = React.useRef(null);

    const validateField = React.useCallback(() => {
        jsoneditorRef.current?.validate().then(errors => {
            setIsValid(!errors.length);
        });
    }, []);

    const onEditJson = React.useCallback(() => {
        const jsonEditor = jsoneditorRef.current;
        const json = jsonEditor?.getText();
        onChange(json);
        try {
            validateField();
        } catch (err) {
            setIsValid(false);
        }
    }, [onChange, validateField]);

    const options = {
        modes: ['code', 'tree'],
        showErrorTable: true,
        search: false,
        indentation: 2,
        enableSort: false,
        enableTransform: false,
        onChange: onEditJson
    };

    const setInitialFormatterValues = React.useCallback(() => {
        if (!loading) {
            setIsValid(true);
        }
    }, [loading]);

    React.useEffect(() => {
        jsoneditorRef.current = new JSONEditor(containerRef.current, options);
        jsoneditorRef.current.setText(values);
        setInitialFormatterValues();
        return () => {
            if (jsoneditorRef.current) {
                jsoneditorRef.current.destroy();
            }
        };
    }, [setInitialFormatterValues]);

    return <div className={css['jsoneditor-field']} ref={containerRef} />;
}
