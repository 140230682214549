import React from 'react';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { Form, Input, InputLabel, Section, SelectHierarchyLabel, SelectLabel } from '@weezevent/nacre';
import { Formatter, Organization, WebhookTypes } from '../../../models';
import { LOGOS, METHOD_OPTIONS } from '../webhookConstants';

import { InfoTextTooltip, LoadingCard, LoadingOptions, DateTimeInput } from '../../../components';

import css from './webhook.module.css';

export const WebHookDetails = ({ id = null, organizationId, form, setForm }) => {
    const { t } = useTranslation();
    const locale = i18n.language;
    const [search, setSearch] = React.useState('');

    const [types, loadingTypes] = WebhookTypes.useApiModel();

    const [organizations, loadingOrganizations] = Organization.useApiModel(
        {
            cache: false,
            allow_cache: false,
            query: { search, limit: 50 }
        },
        [search]
    );

    const options = React.useMemo(() => {
        if (!organizations || loadingOrganizations) {
            return LoadingOptions;
        }
        return organizations.map(org => org.toOption());
    }, [organizations, loadingOrganizations, LoadingOptions]);

    const typesOptions = React.useMemo(() => {
        if (!types || loadingTypes) {
            return [];
        }

        return types.map((it, i) => ({
            key: i,
            value: it.type,
            text: (
                <div className={css['webhook-type']}>
                    <img src={LOGOS[it.product]} alt={'logo'} />
                    <span>{it.name[locale.toLowerCase()] || it.name['en-gb']}</span>
                </div>
            )
        }));
    }, [types, loadingTypes, locale]);

    return (
        <LoadingCard loading={!id && loadingOrganizations && !search}>
            <div className={css['webhook-input']}>
                <InfoTextTooltip className={css['webhook-input-label']} title={t('sauron.webhook.creator')} subtitle={t('sauron.webhook.creator-subtitle')} tooltip={false} />
                <Input
                    value={form.creator || ''}
                    placeholder={t('sauron.webhook.creator-placeholder')}
                    onChange={({ target: { value } }) => {
                        setForm('creator', value);
                    }}
                />
            </div>
            <div className={css['webhook-input']}>
                <InputLabel label={t('sauron.webhook.target')} />
                <Input
                    value={form.target}
                    placeholder={t('sauron.webhook.target_placeholder')}
                    onChange={({ target: { value } }) => {
                        setForm('target', value);
                    }}
                />
            </div>
            <div className={css['webhook-section-title']}>{t(`sauron.webhook.${organizationId ? 'section' : 'section_orga'}`)}</div>
            <Section>
                {!organizationId && !id && (
                    <SelectHierarchyLabel
                        options={options}
                        searchValue={search}
                        placeholder={t('common.select')}
                        label={t('sauron.webhook.organizer')}
                        externalOptions={options}
                        selected={form.criteria?.organization_id}
                        onExternalSearch={value => setSearch(value)}
                        onClear={() => setForm('criteria', { ...form.criteria, organization_id: null })}
                        onChange={value => setForm('criteria', { ...form.criteria, organization_id: value.toString() })}
                    />
                )}
                <SelectLabel
                    options={typesOptions}
                    value={form.criteria?.type}
                    label={t('sauron.webhook.type')}
                    placeholder={t('common.select')}
                    onChange={value => setForm('criteria', { ...form.criteria, type: value })}
                />
            </Section>
        </LoadingCard>
    );
};

export const WebHookOptions = ({ id = null, organizationId, form, setForm }) => {
    const { t } = useTranslation();
    const locale = i18n.language;
    const [search, setSearch] = React.useState('');

    const orgaId = React.useMemo(() => {
        if (!organizationId) {
            return form.criteria?.organization_id;
        }
        return organizationId;
    }, [organizationId, form]);

    const [formatters, loadingFormatters] = Formatter.useApiModel(
        {
            organizationId: orgaId,
            cache: false,
            allow_cache: false,
            query: { search, limit: 100 },
            launch: Boolean(orgaId)
        },
        [orgaId, search]
    );

    // API pagination can exclude the webhook's formatter from the query, so it's safer to fetch it on it's own
    const [currentWebhookFormatter, loadingCurrentWebhookFormatter] = Formatter.useApiModel(
        {
            id: form.formatter,
            organizationId,
            cache: false,
            allow_cache: false,
            launch: Boolean(form.formatter)
        },
        [organizationId, form]
    );

    const isLoading = React.useMemo(() => {
        return !formatters || loadingFormatters || (form.formatter && (!currentWebhookFormatter || loadingCurrentWebhookFormatter));
    }, [form, formatters, loadingFormatters, currentWebhookFormatter, loadingCurrentWebhookFormatter]);

    const options = React.useMemo(() => {
        if (isLoading) {
            return LoadingOptions;
        }
        let _formatters = [...formatters];

        // Webhook has a formatter, but pagination excluded it from the response, so add it to the selector's options
        if (form.formatter && !_formatters.some(it => it.id === form.formatter)) {
            _formatters.unshift(currentWebhookFormatter);
        }
        return _formatters.map(fmt => fmt.toOption());
    }, [isLoading, formatters, currentWebhookFormatter]);

    return (
        <LoadingCard loading={!id && !organizationId && form.criteria?.organization_id && loadingFormatters && !search}>
            <Form.Label label={t('sauron.formatters.formatter')} optionalText={t('common.optional')} />
            <SelectHierarchyLabel
                disabled={!organizationId && !form.criteria?.organization_id}
                value={form.formatter}
                selected={form.formatter}
                options={options}
                searchValue={search}
                placeholder={t('common.select')}
                externalOptions={options}
                onClear={() => setForm('formatter', null)}
                onChange={value => setForm('formatter', value)}
                onExternalSearch={value => setSearch(value)}
            />
            {!id && (
                <div className={css['webhook-input']}>
                    <Form.Label label={t('sauron.webhook.secret')} optionalText={t('common.optional')} />
                    <Input
                        value={form.secret || ''}
                        placeholder={t('sauron.webhook.secret_placeholder')}
                        onChange={({ target: { value } }) => {
                            setForm('secret', value);
                        }}
                    />
                </div>
            )}
            <SelectLabel
                label={t('sauron.webhook.methods.label')}
                placeholder={t('common.select')}
                options={METHOD_OPTIONS}
                onChange={value => {
                    if (value !== 'both') {
                        setForm('criteria', { ...form.criteria, method: value });
                    } else {
                        delete form.criteria.method;
                    }
                }}
                value={form.criteria.method || 'both'}
            />
            <div className={css['webhook-section-title']}>{t('sauron.webhook.expire.title')}</div>
            <Form.Label label={t('sauron.webhook.expire.label')} optionalText={t('common.optional')} />
            <DateTimeInput initialDate={form?.expire_at || null} onChange={value => setForm('expire_at', value)} locale={locale} />
        </LoadingCard>
    );
};
