import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { User } from '../../models';
import { usePermissions } from '../../models/user/permissions';
import { WeezHeader, Icon, Card, Button, ContentCard, FilterSetController, Table, TableCellDropdown, Dropdown, ToastContext } from '@weezevent/nacre';
import { listSelection, requestCount } from '../../utils/utils';

import NewUser from './user/NewUser';
import { LoadingTable, Paginator, SauronEmptyCard, PaginationDropDown, QuickFilters } from '../../components';
import RoleCreationModal from './user/roles/RoleCreationModal';

import css from './list.module.css';

export const UsersList = ({ organizationId = null }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const user = usePermissions();
    const toast = React.useContext(ToastContext);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [defaultPagination] = React.useState(() => ({ limit: 10, offset: 0 }));
    const [queryParams, setQueryParams] = React.useState({ search: '', same_level_users: false, ...defaultPagination });
    const [userRoleModal, setUserRoleModal] = React.useState({ user: null, open: false });
    const [usersIds, setUsersIds] = React.useState([]);
    const [selectedUsers, setSelectedUsers] = React.useState([]);
    const [newUserModal, setNewUserModal] = React.useState(false);

    const [users, loadingUsers, syncUsers] = User.useApiModel(
        {
            cache: false,
            allow_cache: false,
            organizationId,
            query: queryParams,
            onSuccess: response => {
                setUsersIds(response.map(user => user.username));
            },
            onError: () => {
                toast.error(t('sauron.toasts.error'));
            }
        },
        [queryParams, organizationId]
    );

    const usersCount = React.useMemo(() => {
        return requestCount(loadingUsers, users);
    }, [users, loadingUsers, requestCount]);

    let handleSearch = React.useCallback(
        (_, value) => {
            setQueryParams({ search: value, ...defaultPagination });
        },
        [defaultPagination]
    );

    const handlePage = React.useCallback(
        value => {
            setCurrentPage(value);
            setQueryParams({ ...queryParams, offset: queryParams.limit * value });
        },
        [queryParams]
    );

    const handleQuickFilter = React.useCallback(
        checked => {
            setQueryParams({ ...queryParams, same_level_users: checked });
        },
        [queryParams]
    );

    const handleSelect = React.useCallback(
        user => {
            setSelectedUsers(listSelection(selectedUsers, usersIds, user));
        },
        [selectedUsers, usersIds, setSelectedUsers]
    );

    return (
        <>
            <WeezHeader
                rightComponent={[<Button key={0} primary icon={<Icon name={'plus'} />} label={t('sauron.users.add')} onClick={() => setNewUserModal(true)} />]}
                title={t('common.users')}
                backLink={
                    organizationId && {
                        onClick: () => {
                            history.push(`/organizations`);
                        }
                    }
                }
            >
                {t('sauron.users.list')}
            </WeezHeader>

            <Card>
                <ContentCard>
                    <FilterSetController
                        total={usersCount}
                        labelTotal={t('sauron.users.user', { count: usersCount })}
                        onChange={() => {}}
                        search={{
                            onChange: (_, value) => {
                                handleSearch(_, value);
                            },
                            handleReset: () => {
                                handleSearch('');
                            },
                            placeholder: t('common.search'),
                            value: queryParams.search
                        }}
                    />
                    <PaginationDropDown
                        title={t('sauron.users.user_action', { count: selectedUsers.length })}
                        tooltip={t('sauron.users.no_action_tooltip')}
                        label={'sauron.users.page_size'}
                        selected={selectedUsers}
                        count={usersCount}
                        disabled
                        limit={queryParams.limit}
                        pageSizes={[10, 50, 100]}
                        handlePage={handlePage}
                        onChange={value => {
                            setQueryParams({ ...queryParams, limit: value, offset: 0 });
                            setCurrentPage(0);
                        }}
                    />
                    {!organizationId && user.isAdmin() && (
                        <QuickFilters>
                            <QuickFilters.Item label={t('sauron.users.quick-filter')} active={queryParams.same_level_users} onToggle={handleQuickFilter} />
                        </QuickFilters>
                    )}
                </ContentCard>
                {newUserModal && <NewUser organizationId={organizationId} open={newUserModal} onClose={() => setNewUserModal(false)} />}
                <Table useNew selectable onSelectAll={() => handleSelect()} className={css['users-table']}>
                    <Table.THead>
                        <Table.Tr isHeader active={usersIds.length > 0 && usersIds.every(id => selectedUsers.includes(id))}>
                            <th className={css['users-table-medium']}>{t(`common.user`)}</th>
                            <th className={css['users-table-medium']}>{t(`common.email`)}</th>
                            <th className={css['users-table-medium']}>{t(`common.roles`)}</th>
                            <th className={css['users-table-small']}>{t(`common.actions`)}</th>
                        </Table.Tr>
                    </Table.THead>
                    <Table.TBody>
                        {!loadingUsers &&
                            users?.length > 0 &&
                            users?.map(_user => (
                                <UserItem key={_user.username} authenticatedUser={user} user={_user} selected={selectedUsers} onSelect={handleSelect} onOpen={setUserRoleModal} />
                            ))}
                    </Table.TBody>
                </Table>
                <RoleCreationModal open={userRoleModal.open} user={userRoleModal.user} onClose={() => setUserRoleModal({ user: null, open: false })} sync={syncUsers} />
                <LoadingTable loading={loadingUsers} height="30vh" />
                {!loadingUsers &&
                    (users?.length > 0 ? (
                        <Paginator currentPage={currentPage} onPageChange={handlePage} pageSize={queryParams.limit} totalCount={usersCount} />
                    ) : (
                        <SauronEmptyCard title={t('sauron.users.empty-card-title')} subTitle={t('sauron.users.empty-card-subtitle')} />
                    ))}
            </Card>
        </>
    );
};

const UserItem = ({ user, authenticatedUser, selected, onSelect, onOpen }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const resolvedUser = React.useMemo(() => {
        const firstName = user.first_name || '-';
        const lastName = user.last_name.toUpperCase() || '-';

        return {
            name: user.first_name && user.last_name ? `${firstName} ${lastName}` : '-',
            email: user.email || '-',
            roles: user.user_roles?.length
        };
    }, [user]);

    const handleRedirect = React.useCallback(() => {
        history.push(`users/${user.username}`);
    }, [user]);

    return (
        <Table.Tr onSelected={() => onSelect(user.username)} active={selected.includes(user.username)} onClick={handleRedirect}>
            <Table.Td>{resolvedUser.name}</Table.Td>
            <Table.Td>{resolvedUser.email}</Table.Td>
            <Table.Td>{`${resolvedUser.roles} ${t('sauron.users.role', { count: resolvedUser.roles })}`}</Table.Td>

            <TableCellDropdown>
                <Dropdown.Item item={t('sauron.user.see-user')} onClick={handleRedirect} />
                <Dropdown.Item item={t('sauron.user.add-role')} onClick={() => onOpen({ user, open: true })} disabled={!authenticatedUser.canManageRoles()} />
            </TableCellDropdown>
        </Table.Tr>
    );
};
