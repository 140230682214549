import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
    WeezHeader,
    Card,
    Icon,
    Button,
    ContentCard,
    FilterSetController,
    Table,
    TableCellDropdown,
    Dropdown,
    ToastContext,
    Filter,
    CopyToClipboardLink,
    Status,
    StopPropagation
} from '@weezevent/nacre';
import { Webhook } from '../../models';
import { useDateFormat } from '../../hooks';

import { LoadingTable, Paginator, SauronEmptyCard, PaginationDropDown, InfoTextTooltip } from '../../components';
import { handleSearchQuery, listSelection, requestCount } from '../../utils/utils';
import { WebHookActions, WebHooksListActions, WebHookTriggerModal } from './components';
import { ARCHIVED_OPTIONS, STATUS_OPTIONS, TYPE_OPTIONS } from './webhookConstants';

import css from './list.module.css';

const spec = {
    multiple: ['type'],
    fields: ['search', 'type', 'active', 'archived']
};

const DEFAULT_FILTERS = { active: 'true', archived: 'false' };

export const WebhooksList = ({ organizationId }) => {
    const toast = React.useContext(ToastContext);
    const { t } = useTranslation();
    const history = useHistory();
    const [webhooksIds, setWebhooksIds] = React.useState([]);
    const [defaultPagination] = React.useState(() => ({ limit: 25, offset: 0 }));
    const [queryParams, setQueryParams] = React.useState({ search: '', ...defaultPagination });
    const [filters, setFilters] = React.useState({ ...DEFAULT_FILTERS });
    const [currentPage, setCurrentPage] = React.useState(0);
    const [selectedWebhooks, setSelectedWebhooks] = React.useState([]);
    const [disableRetry, setDisableRetry] = React.useState(false);
    const [openTriggerModal, setOpenTriggerModal] = React.useState({ webhook: null });

    const [webhooks, loadingWebhooks, syncWebHooks] = Webhook.useApiModel(
        {
            organizationId,
            cache: false,
            allow_cache: false,
            query: {
                ordering: 'id',
                ...queryParams,
                ...handleSearchQuery(spec, filters, true)
            },
            onSuccess: response => {
                setWebhooksIds(response.map(_webhooks => _webhooks.id));
                setSelectedWebhooks([]);
            },
            onError: () => {
                toast.error(t('sauron.toasts.error'));
            }
        },
        [queryParams, filters, handleSearchQuery, organizationId]
    );

    const webhooksCount = React.useMemo(() => {
        return requestCount(loadingWebhooks, webhooks);
    }, [webhooks, loadingWebhooks]);

    const selectedItems = React.useMemo(() => {
        if (!webhooks || loadingWebhooks) {
            return [];
        }
        return webhooks.filter(it => selectedWebhooks.includes(it.pk));
    }, [webhooks, loadingWebhooks, selectedWebhooks]);

    let handleSearch = React.useCallback(
        (_, value) => {
            setQueryParams({ search: value, ...defaultPagination });
        },
        [defaultPagination]
    );

    const handlePage = React.useCallback(
        value => {
            setCurrentPage(value);
            setQueryParams({ ...queryParams, offset: queryParams.limit * value });
        },
        [queryParams]
    );

    const handleSelect = React.useCallback(
        webhook => {
            setSelectedWebhooks(listSelection(selectedWebhooks, webhooksIds, webhook));
        },
        [selectedWebhooks, webhooksIds, setSelectedWebhooks]
    );

    const handleAction = React.useCallback(
        (webhook, action) =>
            webhook[action]({ organizationId }).then(() => {
                syncWebHooks();
            }),
        [organizationId, syncWebHooks]
    );

    const handleBulkAction = React.useCallback(
        ({ webhook, action }) => {
            Webhook.executeBulkAction({
                type: action,
                organizationId,
                id: webhook?.pk,
                filters: {
                    id__in: selectedWebhooks
                }
            }).then(response => {
                if (action === 'retry_failed_calls') {
                    setDisableRetry(true);
                }
                const wb = webhook || webhooks.find(it => it.id === selectedWebhooks[0]);
                let values = { count: selectedWebhooks.length, name: wb?.name };
                toast.success(t(`sauron.webhook.actions.${action}.progress`, values));

                Webhook.pollBulkAction(response)
                    .then(rsp => {
                        if (rsp.status === 'success') {
                            values.count = rsp.summary.success;
                            toast.success(t(`sauron.webhook.actions.${action}.success`, { ...values }));
                        }
                    })
                    .catch(() => {
                        toast.error(t(`sauron.webhook.actions.${action}.error`, values));
                    })
                    .finally(() => {
                        setDisableRetry(false);
                        syncWebHooks();
                    });
            });
        },
        [organizationId, webhooks, syncWebHooks, selectedWebhooks]
    );

    return (
        <>
            <WeezHeader
                rightComponent={[
                    <Button
                        key={0}
                        primary
                        inverted={true}
                        label={t('sauron.webhooks.enable-notification')}
                        onClick={() => history.push(organizationId ? `/organizations/O${organizationId}/tools/webhooks/new` : `/tools/webhooks/new`)}
                    />
                ]}
                title={t('common.webhooks')}
                backLink={{
                    onClick: () => {
                        history.push(`/organizations`);
                    }
                }}
            >
                {t('sauron.webhooks.list')}
            </WeezHeader>
            <WebHookTriggerModal
                open={Boolean(openTriggerModal.webhook)}
                onClose={() => setOpenTriggerModal({ webhook: null })}
                onAccept={() => handleBulkAction({ action: 'retry_failed_calls', webhook: openTriggerModal.webhook })}
            />
            <Card>
                <ContentCard>
                    <FilterSetController
                        total={webhooksCount}
                        labelTotal={t('sauron.webhooks.webhook', { count: webhooksCount })}
                        labelFilters={t('sauron.webhooks.filters.label-filter')}
                        defaultValues={DEFAULT_FILTERS}
                        initialValues={filters}
                        onChange={value => {
                            setFilters(value);
                        }}
                        onReset={() => setFilters(filters)}
                        search={{
                            onChange: (_, value) => {
                                handleSearch(_, value);
                            },
                            handleReset: () => {
                                handleSearch('');
                            },
                            placeholder: t('sauron.webhook.search'),
                            value: queryParams.search
                        }}
                    >
                        <Filter
                            index={0}
                            name={'type'}
                            multiple
                            labelName={t('sauron.webhooks.filters.types.label')}
                            labelCTA={t('sauron.webhooks.filters.label-cta')}
                            options={TYPE_OPTIONS}
                        />

                        <Filter
                            index={1}
                            name={'active'}
                            labelCTA={t('sauron.webhooks.filters.label-cta')}
                            labelName={t('sauron.webhooks.filters.status.label')}
                            options={STATUS_OPTIONS}
                        />
                        <Filter
                            index={2}
                            name={'archived'}
                            labelCTA={t('sauron.webhooks.filters.label-cta')}
                            labelName={t('sauron.webhooks.filters.archived.label')}
                            options={ARCHIVED_OPTIONS}
                        />
                    </FilterSetController>

                    <PaginationDropDown
                        title={t('sauron.webhooks.webhook_action', { count: selectedWebhooks.length })}
                        tooltip={t('sauron.webhooks.no_action_tooltip')}
                        label={'sauron.webhooks.page_size'}
                        selected={selectedWebhooks}
                        count={webhooksCount}
                        limit={queryParams.limit}
                        pageSizes={[25, 50]}
                        handlePage={handlePage}
                        items={<WebHooksListActions handleAction={handleBulkAction} selectedItems={selectedItems} />}
                        onChange={value => {
                            setQueryParams({ ...queryParams, limit: value, offset: 0 });
                            setCurrentPage(0);
                        }}
                    />
                </ContentCard>

                <Table useNew selectable onSelectAll={() => handleSelect()} className={css['webhooks-table']}>
                    <Table.THead>
                        <Table.Tr isHeader active={webhooksIds.length > 0 && webhooksIds.every(id => selectedWebhooks.includes(id))}>
                            <th className={css['webhooks-table-medium']}>{t(`common.id`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.target`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.creator`)}</th>
                            {!organizationId && <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.organizer`)}</th>}
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.formatter-id`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.type`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.secret`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.date`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.status`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.delivery`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.action`)}</th>
                        </Table.Tr>
                    </Table.THead>
                    <Table.TBody>
                        {!loadingWebhooks &&
                            webhooks?.length > 0 &&
                            webhooks?.map(_webhook => (
                                <WebhookItem
                                    key={_webhook.id}
                                    webhook={_webhook}
                                    selected={selectedWebhooks}
                                    organizationId={organizationId}
                                    onSelect={handleSelect}
                                    handleAction={handleAction}
                                    disableRetry={disableRetry}
                                    onTrigger={setOpenTriggerModal}
                                    handleBulkAction={handleBulkAction}
                                />
                            ))}
                    </Table.TBody>
                </Table>

                <LoadingTable loading={loadingWebhooks} height="30vh" />
                {!loadingWebhooks &&
                    (webhooks?.length > 0 ? (
                        <Paginator currentPage={currentPage} onPageChange={handlePage} pageSize={queryParams.limit} totalCount={webhooksCount} />
                    ) : (
                        <SauronEmptyCard title={t('sauron.webhooks.empty-card-title')} subTitle={t('sauron.webhooks.empty-card-subtitle')} />
                    ))}
            </Card>
        </>
    );
};

const WebhookItem = ({ webhook, selected, onSelect, organizationId, disableRetry, handleAction, handleBulkAction, onTrigger }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const WebhookLink = React.useMemo(() => {
        if (!organizationId) {
            return `/tools/webhooks/${webhook.pk}`;
        }
        return `/organizations/O${organizationId}/tools/webhooks/${webhook.pk}`;
    }, [webhook, organizationId]);

    const expirationDate = React.useMemo(() => {
        if (!webhook?.expire_at) {
            return null;
        }
        let { properDate, properTime } = useDateFormat({ date: webhook?.expire_at, timeStyle: 'short' });

        return { properDate, properTime };
    }, [webhook]);

    return (
        <Table.Tr onSelected={() => onSelect(webhook.id)} active={selected.includes(webhook.id)} onClick={() => history.push(WebhookLink)}>
            <Table.Td>{webhook.pk}</Table.Td>
            <Table.Td className={css['url-wrap']}>
                <CopyToClipboardLink delay={1000} value={webhook.target} copyOnIconClick>
                    <span>{webhook.target}</span>
                </CopyToClipboardLink>
            </Table.Td>
            <Table.Td>{webhook.creator}</Table.Td>
            {!organizationId && <Table.Td>{`O${webhook.organization_id}`}</Table.Td>}
            <Table.Td>
                <HeaderLink item={webhook.formatter} organizationId={organizationId} />
            </Table.Td>
            <Table.Td>{webhook.criteria?.type}</Table.Td>
            <Table.Td>
                <WebHookSecret secret={webhook.secret_is_defined} />
            </Table.Td>
            <Table.Td>
                <InfoTextTooltip title={expirationDate?.properDate || '-'} subtitle={expirationDate?.properTime || ''} tooltip={false} />
            </Table.Td>
            <Table.Td>
                <WebHookStatus status={webhook.active} />
            </Table.Td>
            <Table.Td>
                <WebHookStats success={webhook.success_call_count} fail={webhook.fail_call_count} />
            </Table.Td>
            <TableCellDropdown>
                <InfoTextTooltip title={t('sauron.webhook.trigger_tooltip')} tooltip={!webhook?.can_be_retried}>
                    <Dropdown.Item
                        item={t('sauron.webhook.trigger')}
                        onClick={() => onTrigger({ webhook })}
                        disabled={!webhook?.can_be_retried || !webhook.active || disableRetry}
                    />
                </InfoTextTooltip>
                <WebHookActions webhook={webhook} handleAction={handleAction} actions={[webhook.active ? 'disable' : 'enable', 'archive']} />
            </TableCellDropdown>
        </Table.Tr>
    );
};

const WebHookStats = ({ success, fail }) => {
    const { t } = useTranslation();
    return (
        <div className={css['webhook-calls-block']}>
            <div className={css['webhook-calls-success']}>
                <b>{success} </b>
                {t('sauron.webhook.notifications.delivered_s')}
            </div>
            <div className={css['webhook-calls-fail']}>
                <b>{fail} </b>
                {t('sauron.webhook.notifications.failed_s')}
            </div>
        </div>
    );
};

const WebHookSecret = ({ secret }) => {
    if (!secret) {
        return '-';
    }
    return <Icon name="check-circle" />;
};

const WebHookStatus = ({ status }) => {
    const { t } = useTranslation();

    const values = React.useMemo(() => {
        return {
            color: status ? 'green' : null,
            text: status ? 'active' : 'inactive'
        };
    }, [status]);

    return <Status color={values.color} responsive text={t(`common.${values.text}`)} />;
};

export const HeaderLink = ({ item, organizationId, type }) => {
    const history = useHistory();

    const formatterLink = React.useMemo(() => {
        if (!type) {
            return `/organizations/O${item}/users`;
        }
        if (!organizationId) {
            return `/tools/${type}s/${item}`;
        }
        return `/organizations/O${organizationId}/tools/${type}s/${item}`;
    }, [item, organizationId, type]);

    if (!item) {
        return '-';
    }
    return (
        <StopPropagation>
            <div className={css['external-link']} onClick={() => history.push(formatterLink)}>
                {item}
                <span className={css['external-icon']}>
                    <Icon name="external-link" />
                </span>
            </div>
        </StopPropagation>
    );
};
