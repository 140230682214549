import React from 'react';
import { Trans } from 'react-i18next';
import { WeezTicketLogo, WeezAccessLogo, WeezPayLogo, WeezTargetLogo, WeezFinanceLogo, WeezCrewLogo } from '../../images';

export const ARCHIVED = ['true', 'false'];
export const METHODS = ['both', 'creation', 'edition'];
export const STATUS = ['active', 'inactive'];
export const TYPES = ['transaction', 'transfer', 'wallet', 'refill', 'scan', 'event'];

export const LOGOS = {
    ticket: WeezTicketLogo,
    access: WeezAccessLogo,
    pay: WeezPayLogo,
    target: WeezTargetLogo,
    crew: WeezCrewLogo,
    finance: WeezFinanceLogo
};

export const METHOD_OPTIONS = METHODS.map((it, i) => ({
    key: i,
    text: <Trans i18nKey={`sauron.webhook.methods.${it}`} />,
    value: it
}));

export const TYPE_OPTIONS = TYPES.map(opt => ({ key: opt, name: opt, text: <Trans i18nKey={`sauron.webhooks.filters.types.${opt}`} /> }));

export const STATUS_OPTIONS = STATUS.map(opt => ({ key: opt, name: opt === 'active' ? 'true' : 'false', text: <Trans i18nKey={`sauron.webhooks.filters.status.${opt}`} /> }));

export const ARCHIVED_OPTIONS = ARCHIVED.map(opt => ({ key: opt, name: opt, text: <Trans i18nKey={`sauron.webhooks.filters.archived.${opt}`} /> }));
